import { useStaticQuery, graphql } from "gatsby";

interface configProps {
  field?: string;
  locale?: string;
}

export const getMainConfig = (props: configProps) => {
  const staticConfig = useStaticQuery(config);
  if (
    staticConfig &&
    staticConfig.allStrapiMainConfiguration &&
    staticConfig.allStrapiMainConfiguration.edges &&
    staticConfig.allStrapiMainConfiguration.edges[0] &&
    staticConfig.allStrapiMainConfiguration.edges[0].node
  ) {
    if (props && props.field) {
      const fieldConfig =
        staticConfig.allStrapiMainConfiguration.edges[0].node[props.field];
      if (typeof fieldConfig !== "undefined") {
        return fieldConfig;
      }
    }
    return staticConfig.allStrapiMainConfiguration.edges[0].node;
  }
  return {};
};

export const getTopbarConfig = (props: configProps) => {
  const staticConfig = useStaticQuery(config);
  if (
    staticConfig &&
    staticConfig.allStrapiTopbarConfiguration &&
    staticConfig.allStrapiTopbarConfiguration.edges &&
    staticConfig.allStrapiTopbarConfiguration.edges[0] &&
    staticConfig.allStrapiTopbarConfiguration.edges[0].node
  ) {
    if (props?.locale) {
      const topbarConfigByLocale =
        staticConfig.allStrapiTopbarConfiguration.edges.find((edge) => {
          if (typeof props.locale !== "undefined") {
            return edge.node.locale === props.locale;
          }
        });
      if (topbarConfigByLocale && topbarConfigByLocale.node) {
        return topbarConfigByLocale.node;
      }
    }
    return staticConfig.allStrapiTopbarConfiguration.edges[0].node;
  }
  return {};
};

export const getFooterConfig = (props: configProps) => {
  const staticConfig = useStaticQuery(config);
  if (
    staticConfig &&
    staticConfig.allStrapiFooterConfiguration &&
    staticConfig.allStrapiFooterConfiguration.edges &&
    staticConfig.allStrapiFooterConfiguration.edges[0] &&
    staticConfig.allStrapiFooterConfiguration.edges[0].node
  ) {
    if (props?.locale) {
      const footerConfigByLocale =
        staticConfig.allStrapiFooterConfiguration.edges.find((edge) => {
          if (typeof props.locale !== "undefined") {
            return edge.node.locale === props.locale;
          }
        });
      if (footerConfigByLocale && footerConfigByLocale.node) {
        return footerConfigByLocale.node;
      }
    }
    return staticConfig.allStrapiFooterConfiguration.edges[0].node;
  }
  return {};
};

const config = graphql`
  query allStrapiTopbarConfigurationQueryAndAllStrapiMainConfiguration {
    allStrapiMainConfiguration {
      edges {
        node {
          theme {
            id
            primaryColor
            secondaryColor
            textColor
            primaryButton {
              background
              borderColor
              labelColor
            }
            secondaryButton {
              background
              borderColor
              labelColor
            }
          }
          scripts {
            identifier
            src
            mode
            innerHTML
          }
          favicons {
            rel
            type
            href
            size
          }
          securedBy {
            active
            text
            logo {
              url
              width
              height
              formats {
                thumbnail {
                  url
                }
              }
              localFile {
                id
              }
            }
          }
          defaultCountry
          defaultPrefix
          showDecimals
          checkoutAsGuest
          timezone
        }
      }
    }
    allStrapiTopbarConfiguration {
      edges {
        node {
          locale
          logo {
            url
            width
            height
            formats {
              thumbnail {
                url
              }
            }
            localFile {
              id
            }
          }
          logoAlternativeText
          logoMobileMaxHeight
          logoDesktopMaxHeight
          width
          showLanguages
          showCart
          showWidgets
          showProfile
          menu {
            id
            title
            target
            href
            items {
              href
              label
              target
            }
          }
        }
      }
    }
    allStrapiFooterConfiguration {
      edges {
        node {
          locale
          logo {
            url
            formats {
              thumbnail {
                url
              }
            }
          }
          logoAlternativeText
          copyright
          destination {
            name
            address {
              city
              postalCode
              street
            }
            contact {
              email
              phone
              phonePrefix
            }
          }
          footerLinks {
            column1 {
              href
              label
              target
            }
            column2 {
              href
              label
              target
            }
            column3 {
              href
              image {
                url
              }
              label
              target
            }
          }
          scripts {
            identifier
            src
            mode
            innerHTML
          }
        }
      }
    }
  }
`;
