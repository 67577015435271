import React, { useState, useEffect } from "react";
import axios from "axios";
import { LocaleAsString } from "../../../localeEngine";
import { Typography, Button } from "../../utils";
import Modal from "../../modal";
import { Input } from "../../forms";
import Icon from "../../icons";
import { EMAIL_REGEX } from "../../forms/input/regex";
import { getSession, setSession } from "../../../utils/localStorage";
import { gtmFunction } from "../../../utils/gtm";
import { CONNECT, CONNECT_CLIENT } from "../../../utils/definitions";
import * as styles from "./styles.module.scss";

const StateLogin = ({
  form,
  setCurrentState,
  handleChange,
  closeFn,
  loginCallback,
  continueWithoutLogin,
}) => {
  const [error, setError] = useState(false);
  const [errorType, setErrorType] = useState("login_error");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    gtmFunction("view_login", getSession());
  }, []);

  const onSubmit = (e) => {
    if (!checkInputs()) attemptLogin();
    e.preventDefault();
  };

  const attemptLogin = () => {
    setLoading(true);
    gtmFunction("attempt_login", {
      ...getSession(),
      email: form.email,
    });
    axios
      .post(`${CONNECT}/checkout/login`, {
        client: CONNECT_CLIENT,
        session: getSession().uuid,
        data: {
          email: form.email,
          password: form.password,
        },
      })
      .catch(() => {
        setError(true);
        setErrorType("login_error3");
        setLoading(false);
      })
      .then((response) => {
        if (response.data && response.data.customer) {
          setSession({
            ...getSession(),
            customer: response.data.customer,
            purchaser: response.data.purchaser,
          });
          gtmFunction("successful_login", {
            ...getSession(),
            email: form.email,
          });
          setLoading(false);
          if (loginCallback) loginCallback();
          closeFn();
        } else {
          setError(true);
          setLoading(false);
          setErrorType("login_error");
          if (response.data && response.data.no_account) {
            setErrorType("login_error2");
          }
        }
      });
  };

  const checkInputs = () => {
    if (EMAIL_REGEX.test(form.email) && form.password.length) {
      return false;
    }
    return true;
  };

  const returnError = (errType) => {
    if (errType === "login_error2") {
      return "sign_in.form.login_error2";
    }

    if (errType === "login_error3") {
      return "sign_in.form.login_error3";
    }

    return "sign_in.form.login_error";
  };

  return (
    <div className={styles.content}>
      <span className={styles.header}>
        <span className={styles.icon}>
          <Icon icon="Login" size="Small" />
        </span>
        <Typography component="span" variant="h500-medium">
          <LocaleAsString id="sign_in.header.log_in" />
        </Typography>
      </span>

      <form className={styles.form} onSubmit={onSubmit}>
        {error ? (
          <Typography
            variant="h300-medium"
            component="span"
            className={styles.error}
          >
            <LocaleAsString id={returnError(errorType)} />
          </Typography>
        ) : null}
        <Input
          label={<LocaleAsString id="sign_in.form.email" />}
          icon="Email"
          required
          name="email"
          type="email"
          onChange={(e) => handleChange("email", e)}
          value={form.email}
        />
        <Input
          label={<LocaleAsString id="sign_in.form.password" />}
          icon="Lock"
          required
          name="password"
          type="password"
          onChange={(e) => handleChange("password", e)}
          value={form.password}
        />
        <Button
          className={styles.hyper}
          onClick={() => setCurrentState("reset-password")}
        >
          <Typography variant={"h300-heavy"} component={"span"}>
            <LocaleAsString id="sign_in.form.forgot_password" />
          </Typography>
        </Button>
        <input type="submit" style={{ display: "none" }} />
      </form>
      <Button
        className={styles.cta}
        contentClassName={styles.ctaContent}
        disabled={checkInputs()}
        onClick={() => attemptLogin()}
        loading={loading}
      >
        <Typography
          variant="h400-heavy"
          component="span"
          className={styles.labelLogin}
        >
          <LocaleAsString id="sign_in.submit.cta" />
        </Typography>
      </Button>
      {continueWithoutLogin && (
        <Button
          onClick={() => closeFn()}
          className={styles.continueWithoutLogin}
          contentClassName={styles.continueWithoutLoginContent}
        >
          <Typography variant="h300-heavy" component="span">
            Continue without login
          </Typography>
        </Button>
      )}
    </div>
  );
};

const StateResetPassword = ({
  form,
  setForm,
  setCurrentState,
  handleChange,
  closeFn,
}) => {
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [successModal, setSuccessModal] = useState(false);

  useEffect(() => {
    gtmFunction("view_reset_password", getSession());
  }, []);

  const onSubmit = (e) => {
    if (!checkInputs()) {
      step === 1 ? getTemporary() : changePassword();
    }
    e.preventDefault();
  };

  const getTemporary = () => {
    gtmFunction("attempt_reset_password", getSession());
    setLoading(true);
    axios
      .post(`${CONNECT}/checkout/temporary-password`, {
        client: CONNECT_CLIENT,
        session: getSession().uuid,
        email: form.email,
      })
      .catch(() => setLoading(false))
      .then((response) => {
        setLoading(false);
        setStep(2);
      });
  };

  const checkInputs = () => {
    const { email, temporaryPassword, newPassword, newPassword2 } = form;

    if (step === 1) {
      return !EMAIL_REGEX.test(email);
    } else if (step === 2) {
      return !(
        temporaryPassword &&
        newPassword &&
        newPassword2 &&
        newPassword === newPassword2
      );
    }

    return true;
  };

  const changePassword = () => {
    setLoading(true);
    axios
      .post(`${CONNECT}/checkout/change-password`, {
        client: CONNECT_CLIENT,
        session: getSession().uuid,
        email: form.email,
        temporaryPassword: form.temporaryPassword,
        password: form.newPassword,
      })
      .catch(() => console.log("ERROR"))
      .then((response) => {
        setSuccessModal(true);
        gtmFunction("successful_reset_password", getSession());
      })
      .finally(() => {
        const currentForm = { ...form };
        currentForm.temporaryPassword = "";
        currentForm.newPassword = "";
        currentForm.newPassword2 = "";
        setForm(currentForm);
      });
  };

  const getFormByStep = (step) => {
    if (step === 1) return getTemporaryForm();
    if (step === 2) return getResetForm();
  };

  const getTemporaryForm = () => {
    return (
      <React.Fragment>
        <Typography
          variant="h400-medium"
          component={"p"}
          className={styles.description}
        >
          <LocaleAsString id="reset_password.header.instructions" />
        </Typography>
        <Input
          label={<LocaleAsString id="reset_password.form.email" />}
          icon="Email"
          required
          name="email"
          type="email"
          onChange={(e) => handleChange("email", e)}
          value={form.email}
        />
      </React.Fragment>
    );
  };

  const getResetForm = () => {
    return (
      <React.Fragment>
        <Typography
          variant="h300-medium"
          component={"p"}
          className={styles.description}
        >
          <LocaleAsString id="reset_password.form.submited_temporary_password" />{" "}
          <strong>{form.email}</strong>
          {/* <EditButton onClick={() => setStep(1)} /> */}
        </Typography>
        <Typography
          variant="h300-medium"
          component={"p"}
          className={styles.description}
        >
          <LocaleAsString id="reset_password.form.submited_temporary_password_explain" />
        </Typography>
        <Input
          label={<LocaleAsString id="reset_password.form.temporary_password" />}
          icon="Clock"
          required
          name="temporaryPassword"
          type="text"
          onChange={(e) => handleChange("temporaryPassword", e)}
          value={form.temporaryPassword}
        />
        <Typography
          variant="h300-medium"
          component={"p"}
          className={styles.description}
        >
          <LocaleAsString id="reset_password.form.enter_a_new_password" />
        </Typography>
        <Input
          label={<LocaleAsString id="reset_password.form.new_password" />}
          icon="Lock"
          required
          name="newPassword"
          type="password"
          onChange={(e) => handleChange("newPassword", e)}
          value={form.newPassword}
          autoComplete="new-password"
        />
        <Input
          label={
            <LocaleAsString id="reset_password.form.repeat_new_password" />
          }
          icon="Lock"
          required
          name="newPassword2"
          type="password"
          repeatField={form.newPassword !== form.newPassword2}
          disablePaste={true}
          repeatError="repeatPassword"
          onChange={(e) => handleChange("newPassword2", e)}
          value={form.newPassword2}
          autoComplete="new-password"
        />
      </React.Fragment>
    );
  };

  if (successModal) {
    return (
      <div className={styles.content}>
        <span className={styles.header}>
          <span className={styles.icon}>
            <Icon icon="Lock" size="Small" />
          </span>
          <Typography component="span" variant="h900-medium">
            <LocaleAsString id="reset_password.header.reset_password" />
          </Typography>
        </span>
        <Typography
          component="p"
          variant="h400-heavy"
          className={styles.completed}
        >
          Your password has been reset
        </Typography>
        <Button
          className={styles.cta}
          contentClassName={styles.ctaContent}
          onClick={() => setCurrentState("login")}
        >
          <Typography
            variant="h400-heavy"
            component="span"
            className={styles.labelLogin}
          >
            <LocaleAsString id="reset_password.submit.continue" />
          </Typography>
        </Button>
      </div>
    );
  }

  return (
    <div className={styles.content}>
      <span className={styles.header}>
        <span className={styles.icon}>
          <Icon icon="Lock" size="Small" />
        </span>
        <Typography component="span" variant="h900-medium">
          <LocaleAsString id="reset_password.header.reset_password" />
        </Typography>
      </span>

      <form className={styles.form} onSubmit={onSubmit}>
        {/* dummy inputs to avoid dummy autocompletes */}
        <input type="text" style={{ display: "none" }} />
        <input type="password" style={{ display: "none" }} />
        {getFormByStep(step)}
        <input type="submit" style={{ display: "none" }} />
      </form>
      <Button
        className={styles.cta}
        contentClassName={styles.ctaContent}
        disabled={checkInputs()}
        loading={loading}
        onClick={() => (step === 1 ? getTemporary() : changePassword())}
      >
        <Typography
          variant="h400-heavy"
          component="span"
          className={styles.labelLogin}
        >
          <LocaleAsString id="reset_password.submit.continue" />
        </Typography>
      </Button>
    </div>
  );
};

const ContinueWithoutLogin = ({
  form,
  setCurrentState,
  handleChange,
  closeFn,
  loginCallback,
  continueWithoutLogin,
}) => {
  return (
    <div className={styles.contentContinue}>
      <span className={styles.headerContinue}>
        <span className={styles.icon}>
          <Icon icon="Login" size="Small" />
        </span>
        <Typography
          component="span"
          variant="h500-medium"
          className={styles.accountInfo}
        >
          <LocaleAsString id="lift_tickets.modal.login.description" />
        </Typography>
      </span>
      <div className={styles.buttonsWrapper}>
        <Button
          className={styles.cta}
          contentClassName={styles.ctaContent}
          onClick={() => setCurrentState("login")}
        >
          <Typography variant="h400-medium" component="span">
            <LocaleAsString id="lift_tickets.modal.login" />
          </Typography>
        </Button>
        <Button
          onClick={() => closeFn()}
          className={styles.continueWithoutLogin}
          contentClassName={styles.continueWithoutLoginContent}
        >
          <Typography variant="h400-medium" component="span">
            <LocaleAsString id="lift_tickets.modal.login.without" />
          </Typography>
        </Button>
      </div>
    </div>
  );
};

interface LoginModalProps {
  closeFn: Function;
  loginCallback?: Function;
  defaultEmail?: string;
  continueWithoutLogin?: boolean;
}

const LoginState = ({
  closeFn,
  loginCallback,
  defaultEmail,
  continueWithoutLogin = false,
}) => {
  const defaultCurrentState = continueWithoutLogin
    ? "continueWithoutLogin"
    : "login";
  const [currentState, setCurrentState] = useState(defaultCurrentState);
  const [form, setForm] = useState({
    email: defaultEmail || "",
    password: "",
    temporaryPassword: "",
    newPassword: "",
    newPassword2: "",
  });

  const handleChange = (name, event) => {
    const currentForm = { ...form };
    let value = event.target.value;
    currentForm[name] = value;

    setForm({ ...currentForm });
  };

  const loginState = {
    login: StateLogin,
    "reset-password": StateResetPassword,
    continueWithoutLogin: ContinueWithoutLogin,
  };

  const CurrentComponent = loginState[currentState];

  return (
    <CurrentComponent
      form={form}
      setForm={setForm}
      setCurrentState={setCurrentState}
      handleChange={handleChange}
      closeFn={closeFn}
      loginCallback={loginCallback}
      continueWithoutLogin={continueWithoutLogin}
    />
  );
};

const LoginModal = (props: LoginModalProps) => {
  const {
    closeFn,
    loginCallback,
    defaultEmail,
    continueWithoutLogin = false,
  } = props;

  return (
    <Modal closeFn={closeFn} isAlert={false} size="small">
      <LoginState
        defaultEmail={defaultEmail}
        closeFn={closeFn}
        loginCallback={loginCallback}
        continueWithoutLogin={continueWithoutLogin}
      />
    </Modal>
  );
};

export default LoginModal;
