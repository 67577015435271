const buildGoogleTagManagerProducts = (event, prods, section = false) => {
  let gtmProducts = [];
  prods &&
    prods.forEach((prod, index) => {
      let gtmProduct = {
        item_name: prod.name || prod.title,
        item_id: prod.productId || prod.id,
        price: parseFloat(prod.unitPrice).toFixed(2),
        item_brand: prod.supplier,
        item_category:
          prod.category || prod.productCategory?.type || prod.catalog?.name,
        quantity: prod.quantity,
      };

      if (
        event === "view_item_list" ||
        event === "select_item" ||
        event === "view_item"
      ) {
        gtmProduct.index = index;
      }

      if (section) {
        gtmProduct.list_name = section;
      }

      gtmProducts.push(gtmProduct);
    });

  return gtmProducts;
};

const getRevenue = (prods) => {
  let revenue = 0;
  prods.forEach((prod) => (revenue += prod.quantity * prod.unitPrice));
  return revenue;
};

const getTaxes = (prods) => {
  let taxes = 0;
  prods.forEach((prod) => (taxes += prod.quantity * prod.taxAmount));
  return taxes;
};

export const gtmFunction = (event, data, section = false) => {
  let gtmObject = {};
  switch (event) {
    case "view_item_list":
      gtmObject = {
        event,
        ecommerce: {
          items: buildGoogleTagManagerProducts(event, data, section),
        },
      };
      break;
    case "select_item":
      gtmObject = {
        event,
        ecommerce: {
          items: buildGoogleTagManagerProducts(event, data),
        },
      };
      break;
    case "view_item":
      gtmObject = {
        event,
        ecommerce: {
          items: buildGoogleTagManagerProducts(event, data),
        },
      };
      break;
    case "add_to_cart":
      gtmObject = {
        event,
        ecommerce: {
          items: buildGoogleTagManagerProducts(event, data),
        },
      };
      break;
    case "remove_from_cart":
      gtmObject = {
        event,
        ecommerce: {
          items: buildGoogleTagManagerProducts(event, data),
        },
      };
      break;
    case "begin_checkout":
      gtmObject = {
        event,
        ecommerce: {
          items: buildGoogleTagManagerProducts(event, data),
        },
      };
      break;
    case "purchase":
      gtmObject = {
        event,
        ecommerce: {
          currency: "NOK",
          transaction_id: data.id,
          affiliation: "Bjorli",
          value: getRevenue(data.products),
          tax: getTaxes(data.products),
          shipping: 0,
          items: buildGoogleTagManagerProducts(event, data.products),
        },
      };
      break;
    case "view_login":
      gtmObject = {
        event,
        uuid: data.uuid,
      };
      break;
    case "attempt_login":
      gtmObject = {
        event,
        uuid: data.uuid,
        email: data.email,
      };
      break;
    case "successful_login":
      gtmObject = {
        event,
        uuid: data.uuid,
        email: data.email,
      };
    case "view_reset_password":
      gtmObject = {
        event,
        uuid: data.uuid,
      };
      break;
    case "attempt_reset_password":
      gtmObject = {
        event,
        uuid: data.uuid,
      };
      break;
    case "successful_reset_password":
      gtmObject = {
        event,
        uuid: data.uuid,
      };
    default:
      break;
  }

  if (
    typeof window !== "undefined" &&
    typeof window.dataLayer !== "undefined"
  ) {
    if (gtmObject.ecommerce) {
      window.dataLayer.push({ ecommerce: null });
    }
    window.dataLayer.push(gtmObject);
  }
};
