// extracted by mini-css-extract-plugin
export var content = "styles-module--content--UVH_H";
export var header = "styles-module--header--1ZfBV";
export var icon = "styles-module--icon--2H-Np";
export var completed = "styles-module--completed--25x2U";
export var form = "styles-module--form--19kYz";
export var error = "styles-module--error--1NxLk";
export var description = "styles-module--description--2tgHg";
export var desc = "styles-module--desc--1J2LC";
export var hyper = "styles-module--hyper--vswmx";
export var labelLogin = "styles-module--labelLogin--3DMFt";
export var cta = "styles-module--cta--3pEpC";
export var ctaContent = "styles-module--ctaContent--2Zj6W";
export var continueWithoutLogin = "styles-module--continueWithoutLogin--1ssfa";
export var continueWithoutLoginContent = "styles-module--continueWithoutLoginContent--2KoLh";
export var contentContinue = "styles-module--contentContinue--1kERz";
export var headerContinue = "styles-module--headerContinue--3xs9k";
export var accountInfo = "styles-module--accountInfo--aMpp9";
export var buttonsWrapper = "styles-module--buttonsWrapper--3s_ug";
export var stickyBar = "styles-module--stickyBar--1FAeW";
export var closeBtn = "styles-module--closeBtn--2kDsY";